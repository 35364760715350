import React from "react"
import * as actions from "modules/auth/actions"
import * as appActions from "actions"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import LoginComponent from "modules/auth/components/LoginComponent"

const LoginContainer = props => <LoginComponent {...props}/>

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators(Object.assign({}, actions, appActions), dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)