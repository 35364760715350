import React from "react"
import { isTouchDevice } from "helper"

export default class ResponsiveApp extends React.Component {
    checkIfTouchDevice = this.checkIfTouchDevice.bind(this)

    componentDidMount(){
        this.checkIfTouchDevice()
        this.props.actionStopLoading()
        window.addEventListener("resize", this.checkIfTouchDevice)
    }

    componentWillUnmount(){
        window.removeEventListener("resize", this.checkIfTouchDevice)
    }

    checkIfTouchDevice(){
        const { app, theme } = this.props

        if(app && isTouchDevice(theme) !== app.isTouchDevice) this.props.actionSetIsTouchDevice(isTouchDevice(theme))
    }

    render = () => this.props.children
}