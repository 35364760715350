import React, { Fragment } from "react"
import { Redirect } from "react-router-dom"
import { authToken } from "config"

export default function AuthRequired(props){
    if(localStorage.getItem(authToken.key)){
        return(
            <Fragment>
                {props.orComponent}
            </Fragment>
        )
    } else {
         return <Redirect to="/login"/>
        // return(
        //     <Fragment>
        //         {props.orComponent}
        //     </Fragment>
        // )
    }
}