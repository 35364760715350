import React, { Component } from 'react';
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import IconButton from "@material-ui/core/IconButton"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"

export default class CommonPasswordField extends Component {
    state = {
        isPasswordShown: false
    }

    togglePasswordView = () => {
        const { isPasswordShown } = this.state
        this.setState({ isPasswordShown: !isPasswordShown })
    }

    render() {
        const { isPasswordShown } = this.state
        const { errorText, disableFullWidth, ...restProps } = this.props

        
        return (
            <TextField
                variant="outlined"
                type={isPasswordShown ? "text" : "password" }
                fullWidth={!disableFullWidth}
                error={Boolean(errorText)}
                helperText={errorText}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={this.togglePasswordView} aria-label="toggle password visibility" tabIndex="-1">
                              {!isPasswordShown ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                { ...restProps }
            />
        )

    }
}