import Axios from "axios"

export default async ({ url, body, config }) => {
    try {
        const apiResponse = await Axios.post(url, body, config)

        return apiResponse
    } catch (error) {
        console.error("Error in POST request, URL :", url, "Error: ", error)
    }
}