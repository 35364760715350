import { UiConfig } from "config"
import moment from "moment"

export default (date,format,timeZone) => {
    if(date && !timeZone){
        return moment.utc(date).format(format ? format : UiConfig.DATE_FORMAT)
    } else if(date && timeZone){
        return moment.utc(date).utcOffset(timeZone).format(format ? format : UiConfig.DATE_FORMAT)
    }
    return date
}