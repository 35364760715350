 import React, { lazy } from "react"
import LoginContainer from "modules/auth/containers/LoginContainer"
// import CategoryIcon from '@material-ui/icons/Category';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import PeopleIcon from '@material-ui/icons/People';
import CancelIcon from '@material-ui/icons/Cancel';
import StarsIcon from '@material-ui/icons/Stars';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import EventIcon from '@material-ui/icons/Event';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import DateRangeIcon from '@material-ui/icons/DateRange';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import BusinessIcon from '@material-ui/icons/Business';
import TocIcon from '@material-ui/icons/Toc';
import ExtensionIcon from '@material-ui/icons/Extension';

const OperatorOverviewContainer = lazy(() => import("modules/operators/containers/OperatorOverviewContainer"))
const SpecialitiesOverviewContainer = lazy(() => import("modules/specialities/containers/SpecialitiesOverviewContainer"))
const DoctorsOverviewContainer = lazy(() => import("modules/doctors/containers/DoctorsOverviewContainer"))
const SubSpecialitiesOverviewContainer = lazy(() => import("modules/specialities/containers/SubSpecialitiesOverviewContainer"))
const AppointmentsOverviewContainer = lazy(() => import("modules/appointments/containers/AppointmentsOverviewContainer"))
const AppointmentDetailsContainer = lazy(() => import("modules/appointments/containers/AppointmentDetailsContainer"))
const AppointmentCancelRequestOverviewContainer = lazy(() => import("modules/appointments/containers/AppointmentCancelRequestOverviewContainer"))
const AppointmentRescheduleRequestOverviewContainer = lazy(() => import("modules/appointments/containers/AppointmentRescheduleRequestOverviewContainer"))
const AppointmentExpiredPaymentContainer = lazy(() => import("modules/appointments/containers/AppointmentExpiredPaymentContainer"))
const PatientsOverviewContainer = lazy(() => import("modules/clients/containers/ClientsOverviewContainer"))
const DiagnosticCentersOverviewContainer = lazy(() => import("modules/diagnosticCenters/containers/DiagnosticCentersOverviewContainer"))
const DiagnosticOrderOverviewContainer = lazy(() => import("modules/diagnosticOrders/containers/DiagnosticOrderOverviewContainer"))
const DiagnosticOrderDetailsContainer = lazy(() => import("modules/diagnosticOrders/containers/DiagnosticOrderDetailsContainer"))
const MedicalOrderOverviewContainer = lazy(() => import("modules/medicalOrders/containers/MedicalOrderOverviewContainer"))
const MedicalOrderDetailsContainer = lazy(() => import("modules/medicalOrders/containers/MedicalOrderDetailsContainer"))

// const CategoryOverviewContainer = lazy(() => import("modules/category/containers/CategoryOverviewContainer"))
// const SubCategoryOverviewContainer = lazy(() => import("modules/category/subCategory/containers/SubCategoryOverviewContainer"))
const ProductsOverviewContainer = lazy(() => import("modules/products/containers/ProductsOverviewContainer"))
const DiscountOverviewContainer = lazy(() => import("modules/discounts/containers/DiscountOverviewContainer"))

const WithoutAuthRoutes = [
    {
        path: "/login",
        exact: true,
        component: LoginContainer,
        title: "Auth",
    },
    {
        path: "/",
        exact: true,
        component: LoginContainer,
        title: "Auth",
    },
]

// AUTH REQUIRED ROUTES
const RoutesOnSidebar = [
    {
        path: "/operators",
        exact: true,
        component: <OperatorOverviewContainer />,   
        title: "Operators",
        icon: <PeopleIcon />
    },
    {
        path: "/specialities",
        exact: true,
        component: <SpecialitiesOverviewContainer />,   
        title: "Specialities",
        icon: <StarsIcon />
    },
    {
        path: "/doctors",
        exact: true,
        component: <DoctorsOverviewContainer />,   
        title: "Doctors",
        icon: <LocalHospitalIcon />
    },
    {
        path: "/appointments",
        exact: true,
        component: <AppointmentsOverviewContainer />,   
        title: "Appointments",
        icon: <EventIcon />
    },
    {
        path: "/cancel-appointment",
        exact: true,
        component: <AppointmentCancelRequestOverviewContainer />,   
        title: "Cancellation Request",
        icon: <EventBusyIcon />
    },
    {
        path: "/reschedule-appointment",
        exact: true,
        component: <AppointmentRescheduleRequestOverviewContainer />,   
        title: "Reschedule Request",
        icon: <DateRangeIcon />
    },
    {
        path: "/expired-payments",
        exact: true,
        component: <AppointmentExpiredPaymentContainer />,   
        title: "Expired Payments",
        icon: <CancelIcon />
    },
    {
        path: "/diagnostic-centers",
        exact: true,
        component: <DiagnosticCentersOverviewContainer />,   
        title: "Diagnostic Centers",
        icon: <BusinessIcon />
    },
    {
        path: "/medical-products",
        exact: true,
        component: <ProductsOverviewContainer />,
        title: "Medical Products",
        icon: <LocalMallIcon />
    },
    {
        path: "/diagnostic-orders",
        exact: true,
        component: <DiagnosticOrderOverviewContainer />,   
        title: "Diagnostic Orders",
        icon: <TocIcon />
    },
    {
        path: "/medical-orders",
        exact: true,
        component: <MedicalOrderOverviewContainer />,   
        title: "Medical Orders",
        icon: <TocIcon />
    },
    {
        path: "/discounts",
        exact: true,
        component: <DiscountOverviewContainer />,   
        title: "Discounts",
        icon: <ExtensionIcon />
    },
    {
        path: "/clients",
        exact: true,
        component: <PatientsOverviewContainer />,   
        title: "Clients",
        icon: <SupervisedUserCircleIcon />
    },

    // {
    //     path: "/category",
    //     exact: true,
    //     component: <CategoryOverviewContainer />,
    //     title: "Categories",
    //     icon: <CategoryIcon />,
    //     children: [
    //         {
    //             path: "/sub-category",
    //             exact: true,
    //             component: <SubCategoryOverviewContainer />,
    //             title: "Sub Categories",
    //             icon: <CategoryIcon />
    //         }
    //     ]
    // },
]

const RoutesForSpecialities = [
    {
        path: "/sub-specialities",
        exact: true,
        component: <SubSpecialitiesOverviewContainer />,   
        title: "Sub Specialities",
       // icon: <LocalHospitalIcon />
    },
];

const RoutesForAppointments = [
    {
        path: "/appointment-details",
        exact: true,
        component: <AppointmentDetailsContainer />,   
        title: "Appointment Details",
       // icon: <LocalHospitalIcon />
    },
];

const RoutesForDiagnosticAppointments = [
    {
        path: "/diagnostic-order-details",
        exact: true,
        component: <DiagnosticOrderDetailsContainer />,   
        title: "Diagnostic Order Details",
       // icon: <LocalHospitalIcon />
    },
];

const RoutesForMedicalOrders = [
    {
        path: "/medical-order-details",
        exact: true,
        component: <MedicalOrderDetailsContainer />,   
        title: "Medical Order Details",
       // icon: <LocalHospitalIcon />
    },
];

const RoutesNotOnSidebar = [
    
]

export {
    WithoutAuthRoutes,
    RoutesOnSidebar,
    RoutesNotOnSidebar,
    RoutesForSpecialities,
    RoutesForAppointments,
    RoutesForDiagnosticAppointments,
    RoutesForMedicalOrders
}