import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import AppBar from '@material-ui/core/AppBar'
// import Toolbar from '@material-ui/core/Toolbar'
// import Typography from '@material-ui/core/Typography'
import CssBaseline from '@material-ui/core/CssBaseline'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
// import Box from '@material-ui/core/Box'
// import Container from '@material-ui/core/Container'
// import CommonImage from 'modules/common/components/CommonImage'
// import Grid from '@material-ui/core/Grid'
// import AppFooter from "modules/common/components/AppFooter"
import { makeStyles } from "@material-ui/core/styles"
import { authToken, homePageAfterAuth } from 'config'
// import Particles from 'react-particles-js'
// import { Theme } from 'config'

const useStyles = makeStyles(theme => ({
    animatedParticles: {
        position: "fixed",
        width: "100%",
        height: "100%"
    },
    appContainer: {
        display: "flex",
        justifyContent: "flex-end",
        boxSizing: "border-box",
    },
    publicContainer: {
        flexGrow: 0,

        [theme.breakpoints.up("md")]: {
            maxWidth: "41.666667%",
            flexBasis: "41.666667%",
        }
    }
}))

function ElevationScroll(props) {
    const { children, window } = props
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    })

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    })
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
}

function StyledAuthLayout(props){
    const classes = useStyles()
    return props.children(classes)
}

export default class AuthLayout extends Component {
    componentDidMount(){
        // if logged in user, go to agent home page
        if(localStorage.getItem(authToken.key)) this.props.history.push(homePageAfterAuth)
    }

    render(){
        return (
            <StyledAuthLayout>
                {classes => (
                    <React.Fragment>
                        <CssBaseline />
                        {/* <ElevationScroll {...this.props}>
                            <AppBar color="inherit">
                                <Toolbar className={classes.toolbar}>
                                    <CommonImage
                                        height="40px"
                                        src="images/logo/flight_local_logo.svg" 
                                        alt="flight-local-logo-circle"
                                    />
                                </Toolbar>
                            </AppBar>
                        </ElevationScroll> */}
                        {/* <Toolbar /> */}
                        <div className={classes.appContainer}>
                            <div className={classes.publicContainer}>
                                { this.props.children }
                            </div>
                        </div>
                            
                        
                        {/* <AppFooter/> */}
                    </React.Fragment>
                )}
            </StyledAuthLayout>
        )
    }
    
}