const initialState = {
    phoneNumber: "",
    credentials: {}
}

export default (state = initialState, action) => {
    switch (action.type) {        
        case 'GET_PHONE_NUMBER':
            return { ...state, phoneNumber: action.payload }
        
        case 'GET_DEVICE_UUID':
            return { ...state, deviceUuid: action.payload }

        case 'SET_AUTH_TOKENS':
            return { ...state, credentials: action.payload }
   
        default:
            return state
    }
}