import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Collapse from '@material-ui/core/Collapse'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',

        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const useStylesForTrigger = makeStyles(theme => ({
    triggerContainer: {
        display: "block",
        width: "200px",
        height: "200px",
        // border: "1px solid currentcolor",
        // borderRadius: "3px",
        // fontSize: "30px",
        // padding: "1em",
        // position: "relative",
        // marginBottom: ".25em",
        // verticalAlign: "top",
        // transition: ".3s color, .3s border, .3s transform, .3s opacity"
    }
}))

function AppLoaderTrigger(props){
    const classes = useStylesForTrigger()
    return props.children(classes)
}

export function AppLoader(props) {
    const classes = useStyles();

    return (
        <Collapse in={props.app && props.app.isLoadingApp}>
            <div className={classes.root}>
                <LinearProgress />
            </div>
        </Collapse>
    );
}

export default class AppLoaderTriggerComponent extends Component {
    componentDidMount(){
        this.props.actionToggleAppLoader(true)
    }

    componentWillUnmount(){
        this.props.actionToggleAppLoader(false)
    }

    render(){
        return(
            <AppLoaderTrigger>
                {classes => (
                    <div className={classes.triggerContainer}>
                        <div className="simpler-loader"/>
                    </div>
                )}
            </AppLoaderTrigger>
            
        )
    }
}