import client from "apolloClient"
import logoutOperator from "gql/auth/mutations/logoutOperator"

export default () => async () => {
    try {
        const logoutResponse = await client.mutate({
            mutation: logoutOperator
        }).then(response => response.data.logoutOperator)

        return logoutResponse
        
    } catch (error) {
        console.error("Err in logoutOperator", error)
    }
}