import gql from "graphql-tag"

export default gql`
    mutation logoutOperator {
        logoutOperator{
            message
            statusCode
            result{
                id
            }
        }
    }
`