import gql from "graphql-tag"

export default gql`
    query getDeviceUuid {
        getDeviceUuid{
            message
            statusCode
            result{
                deviceUuid
            }
        }
    }
`