import client from "apolloClient"
import loginOperator from "gql/auth/mutations/loginOperator"

export default ({ auth, password, device }) => async () => {
    try {
        const loginResponse = await client.mutate({
            mutation: loginOperator,
            variables: { auth, password, device }
        }).then(response => response.data.loginOperator)

        return loginResponse

        // console.log("loginResponse", loginResponse)
    } catch (error) {
        console.error("Err in loginOperator", error)        
    }
}