import client from "apolloClient"
import getDeviceUuid from "gql/auth/queries/getDeviceUuid"

export default () => async () => {
    try {
        const deviceUuidResponse = await client.query({
            query: getDeviceUuid
        }).then(response => response.data.getDeviceUuid.result.deviceUuid)

        return deviceUuidResponse
    } catch (error) {
        console.error("Err in getDeviceUuid", error)
    }
}